
    import './styles.scoped.css';
    export default {
  "header-cell": "awsui_header-cell_1spae_gux99_145",
  "header-cell-fake-focus": "awsui_header-cell-fake-focus_1spae_gux99_179",
  "header-cell-sticky": "awsui_header-cell-sticky_1spae_gux99_200",
  "header-cell-stuck": "awsui_header-cell-stuck_1spae_gux99_203",
  "header-cell-variant-full-page": "awsui_header-cell-variant-full-page_1spae_gux99_203",
  "header-cell-hidden": "awsui_header-cell-hidden_1spae_gux99_209",
  "header-cell-sortable": "awsui_header-cell-sortable_1spae_gux99_212",
  "sticky-cell": "awsui_sticky-cell_1spae_gux99_215",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_1spae_gux99_223",
  "has-selection": "awsui_has-selection_1spae_gux99_223",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_1spae_gux99_226",
  "resize-divider": "awsui_resize-divider_1spae_gux99_231",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_1spae_gux99_238",
  "sorting-icon": "awsui_sorting-icon_1spae_gux99_258",
  "edit-icon": "awsui_edit-icon_1spae_gux99_266",
  "header-cell-content": "awsui_header-cell-content_1spae_gux99_272",
  "header-cell-content-expandable": "awsui_header-cell-content-expandable_1spae_gux99_278",
  "header-cell-disabled": "awsui_header-cell-disabled_1spae_gux99_330",
  "header-cell-sorted": "awsui_header-cell-sorted_1spae_gux99_330",
  "header-cell-text": "awsui_header-cell-text_1spae_gux99_344",
  "header-cell-text-wrap": "awsui_header-cell-text-wrap_1spae_gux99_348",
  "header-cell-ascending": "awsui_header-cell-ascending_1spae_gux99_354",
  "header-cell-descending": "awsui_header-cell-descending_1spae_gux99_355",
  "is-visual-refresh": "awsui_is-visual-refresh_1spae_gux99_365",
  "has-striped-rows": "awsui_has-striped-rows_1spae_gux99_469",
  "sticky-cell-pad-inline-start": "awsui_sticky-cell-pad-inline-start_1spae_gux99_469"
};
  