
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_h9bes_149",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_h9bes_198",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_h9bes_213",
  "navigation": "awsui_navigation_7nfqu_h9bes_228",
  "tools": "awsui_tools_7nfqu_h9bes_229",
  "split-panel-side": "awsui_split-panel-side_7nfqu_h9bes_261",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_h9bes_268",
  "panel-hidden": "awsui_panel-hidden_7nfqu_h9bes_281",
  "toolbar-container": "awsui_toolbar-container_7nfqu_h9bes_291",
  "notifications-container": "awsui_notifications-container_7nfqu_h9bes_297",
  "main-landmark": "awsui_main-landmark_7nfqu_h9bes_304",
  "main": "awsui_main_7nfqu_h9bes_304",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_h9bes_313",
  "content-header": "awsui_content-header_7nfqu_h9bes_323",
  "content": "awsui_content_7nfqu_h9bes_323",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_h9bes_333"
};
  